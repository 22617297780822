import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import '../css/pricing.scss'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Navbar } from '../components/navbar'

const Pricing = ({ data, path }) => {
	return (
		<Layout>
			<SEO
				title="The Best price for CRM for Real Estate to Manage Properties"
				keywords={['crm property management']}
				description="we've developed the best CRM software for real estate investors to streamline property management. with the best price. Join our community today"
			/>
			<div className="pricing-page">
				<Navbar path={path} />
				<div className="page-content">
					<MDXRenderer>{data.Pricing.nodes[0]?.body}</MDXRenderer>
				</div>
				<div className="section-parent section-container footer-section">
					<MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
				</div>
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query PricingQuery {
		Header: allMdx(filter: { frontmatter: { title: { eq: "Header" } } }) {
			nodes {
				body
			}
		}
		Pricing: allMdx(filter: { frontmatter: { title: { eq: "New Pricing" } } }) {
			nodes {
				body
			}
		}
		Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
			nodes {
				body
			}
		}
	}
`

function HOC(Page) {
	return function (props) {
		const isBrowser = typeof window !== 'undefined'

		return isBrowser ? <Page {...props} /> : <div></div>
	}
}

export default HOC(Pricing)
